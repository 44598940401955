<template>
  <Loader
    v-if="$apollo.queries.transactions.loading"
    color="#FF035A"
    size="50"
  />
  <div class="wallet" v-else>
    <div class="content">
      <div class="left-panel">
        <div class="tabs">
          <div class="tab-nav">
            <div class="nav active">
              <div class="title">
                <span class="text">All Transactions</span>
              </div>
              <span class="underline"></span>
            </div>
          </div>
          <div class="tab-content">
            <div class="payments">
              <div
                v-for="({ avatar_url, name, date, pending, amount },
                i) in transactions.all"
                :key="i"
                class="payment"
              >
                <div class="user-image" v-if="avatar_url">
                  <img :src="avatar_url" />
                </div>
                <div class="user-initials" v-else>
                  {{ name | initials }}
                </div>
                <div class="user-details">
                  <span class="name">{{ name }}</span>
                  <span class="date">{{ date }}</span>
                </div>
                <div class="payment-details">
                  <span v-if="pending" class="status">Pending</span>
                  <span class="amount" :class="{ accent: amount > 0 }">
                    {{
                      (amount > 0 ? "+ $" : "- $") + Math.abs(amount).toFixed(2)
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-panel">
        <Loader
          v-if="['balance', 'myArtist'].some(el => $apollo.queries[el].loading)"
          color="#FF035A"
          size="30"
        />
        <div v-else>
          <div class="user">
            <div class="image" v-if="myArtist.avatar_url">
              <img :src="myArtist.avatar_url" />
            </div>
            <div class="initials" v-else>
              {{ myartist.name | initials }}
            </div>
            <div class="balance">
              <p class="balance-text">Available balance</p>
              <p class="balance-amount">${{ balance.toFixed(2) }}</p>
            </div>
            <button
              class="primary"
              :disabled="submitting || !balance"
              @click="requestPayout"
            >
              {{ submitting ? "Submitting..." : "Request Payout" }}
            </button>
          </div>
          <div class="payment-methods">
            <span class="title">Payment Methods</span>
            <div class="description">
              Your charges will be deducted from the default card shown below.
              This can be changed by adding a new card and making it the default
              using the menu on the right.
            </div>
            <div class="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Brand</th>
                    <th>Default</th>
                    <th>Number (last 4)</th>
                    <th>Exp. date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="loading-table"
                    v-if="$apollo.queries.paymentMethods.loading"
                  >
                    <td colspan="3">
                      <div>
                        <span>Loading</span>
                        <div>
                          <Loader size="18" width="2" color="#FF035A" />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="no-data" v-else-if="!paymentMethods.length">
                    <td colspan="5">
                      <div>
                        No Payment Methods Available
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-for="{
                      id,
                      card: {
                        default: isDefault,
                        brand,
                        last4,
                        exp_month,
                        exp_year
                      }
                    } in paymentMethods"
                    :key="id"
                  >
                    <td>{{ brand | brandName }}</td>
                    <td>
                      <div class="check" v-if="isDefault">
                        <img src="../assets/icons/Check.svg" />
                      </div>
                    </td>
                    <td>{{ last4 }}</td>
                    <td>
                      {{ exp_month > 9 ? exp_month : `0${exp_month}` }}
                      /
                      {{ exp_year }}
                    </td>
                    <td class="actions">
                      <div class="icon" @click="toggleShowActions(id)">
                        <span class="dot" v-for="i in 3" :key="i" />
                      </div>
                      <div
                        class="menu"
                        :id="`actions-menu-${id}`"
                        v-show="actionsPaymentMethod === id"
                        @focusout="toggleShowActions()"
                        tabindex="1"
                      >
                        <span
                          class="item"
                          @click="updateDefaultPaymentMethod(id)"
                          v-if="!isDefault"
                        >
                          {{
                            updatingDefaultPM ? "Updating..." : "Set Default"
                          }}
                        </span>
                        <span class="item" @click="removePaymentMethod(id)">
                          {{ removingPM ? "Removing..." : "Remove" }}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="add-card">
              <button class="primary" @click="showModal = 'add-card'">
                Add New Card
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddCard v-if="showModal === 'add-card'" @close="showModal = ''" />
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import AddCard from "@/components/modals/AddCard.vue";

import FETCH_TRANSACTIONS from "@/api/queries/FETCH_TRANSACTIONS.gql";
import GET_BALANCE from "@/api/queries/GET_BALANCE.gql";
import GET_ARTIST from "@/api/queries/GET_ARTIST.gql";
import REQUEST_PAYOUT from "@/api/mutations/REQUEST_PAYOUT.gql";
import GET_PAYMENT_METHODS from "@/api/queries/GET_PAYMENT_METHODS.gql";
import REMOVE_PAYMENT_METHOD from "@/api/mutations/REMOVE_PAYMENT_METHOD.gql";
import UPDATE_DEFAULT_PAYMENT_METHOD from "@/api/mutations/UPDATE_DEFAULT_PAYMENT_METHOD.gql";

export default {
  name: "Wallet",
  components: { Loader, AddCard },
  data() {
    return {
      submitting: false,
      showModal: "",
      actionsPaymentMethod: null,
      removingPM: false,
      updatingDefaultPM: false
    };
  },
  filters: {
    initials(name) {
      return name
        ?.split(" ")
        .slice(0, 2)
        .map(el => el.charAt(0).toUpperCase())
        .join("");
    },
    brandName(val) {
      let name;
      switch (val) {
        case "mastercard":
          name = "MasterCard";
          break;
        case "visa":
          name = "VISA";
          break;
        default:
          name = val;
      }
      return name;
    }
  },
  updated: function() {
    this.$nextTick(function() {
      if (this.actionsPaymentMethod)
        document
          .querySelector(`#actions-menu-${this.actionsPaymentMethod}`)
          .focus();
    });
  },
  methods: {
    toggleShowActions(val) {
      if (this.removingPM || this.updatingDefaultPM) return;
      this.actionsPaymentMethod = val;
    },
    getDayAndTime(timestamp) {
      const createdAt = new Date(timestamp);
      const date = createdAt.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric"
      });
      const time = createdAt.toLocaleTimeString("en-US", {
        hour12: true,
        hour: "2-digit",
        minute: "2-digit"
      });

      return `${date}, ${time}`;
    },
    formatTransactions(transactions) {
      let all = [];
      let sent = [];
      let received = [];

      transactions.forEach(el => {
        const isFromMe =
          el.artistFrom.artist_users[0].user_id ===
          this.$store.getters["account/getUserId"];

        const artist = isFromMe ? el.artistTo : el.artistFrom;

        let transaction = {
          name: artist.name,
          avatar_url: artist.avatar_url,
          date: this.getDayAndTime(el.created_at),
          pending: el.status !== "succeeded",
          amount: isFromMe ? -el.amount : el.amount
        };

        all.push(transaction);
        isFromMe ? sent.push(transaction) : received.push(transaction);
      });

      return { all, sent, received };
    },
    requestPayout() {
      this.submitting = true;
      this.$apollo.mutate({
        mutation: REQUEST_PAYOUT,
        update: (store, { data: { stripe_initiate_payout } }) => {
          if (!stripe_initiate_payout.success) return;
          const data = store.readQuery({ query: GET_BALANCE });
          data.stripe_get_balance.available[0].amount = 0;
          store.writeQuery({ query: GET_BALANCE, data });
          this.submitting = false;
        }
      });
    },
    removePaymentMethod(payment_method) {
      if (this.removingPM || this.updatingDefaultPM) return;
      this.removingPM = true;
      return this.$apollo.mutate({
        mutation: REMOVE_PAYMENT_METHOD,
        variables: { payment_method },
        update: store => {
          const data = store.readQuery({
            query: GET_PAYMENT_METHODS
          });

          data.stripe_get_payment_methods = data.stripe_get_payment_methods.flatMap(
            item => {
              if (item.id !== payment_method) return [item];
              return [];
            }
          );

          store.writeQuery({
            query: GET_PAYMENT_METHODS,
            data
          });

          this.removingPM = false;
          this.actionsPaymentMethod = null;
        }
      });
    },
    updateDefaultPaymentMethod(payment_method) {
      if (this.removingPM || this.updatingDefaultPM) return;
      this.updatingDefaultPM = true;
      return this.$apollo.mutate({
        mutation: UPDATE_DEFAULT_PAYMENT_METHOD,
        variables: { payment_method },
        update: store => {
          const data = store.readQuery({
            query: GET_PAYMENT_METHODS
          });

          data.stripe_get_payment_methods = data.stripe_get_payment_methods.map(
            ({ id, card, ...rest }) => ({
              id,
              card: {
                ...card,
                default: id === payment_method
              },
              ...rest
            })
          );

          store.writeQuery({
            query: GET_PAYMENT_METHODS,
            data
          });

          this.updatingDefaultPM = false;
          this.actionsPaymentMethod = null;
        }
      });
    }
  },
  apollo: {
    balance: {
      query: GET_BALANCE,
      update: ({ stripe_get_balance }) =>
        stripe_get_balance ? stripe_get_balance.available[0].amount / 100 : 0
    },
    myArtist: {
      query: GET_ARTIST,
      variables() {
        return {
          id: this.$store.getters["account/getArtistId"],
          userId: this.$store.getters["account/getUserId"]
        };
      },
      update: ({ artists_by_pk }) => artists_by_pk
    },
    transactions: {
      query: FETCH_TRANSACTIONS,
      variables() {
        return { userId: this.$store.getters["account/getUserId"] };
      },
      update({ transactions }) {
        return this.formatTransactions(transactions);
      }
    },
    paymentMethods: {
      query: GET_PAYMENT_METHODS,
      update: ({ stripe_get_payment_methods }) => stripe_get_payment_methods
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";

.wallet {
  width: 100%;
  height: 100%;

  .content {
    display: grid;
    grid-template-columns: 510px 1fr;

    .left-panel {
      padding: 30px 60px 0;
      height: 100vh;
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: $accent rgba(#cdcccc, 0.3);

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        @include background-opacity(#cdcccc, 0.3);
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $accent;
        border-radius: 5px;
      }

      .filters {
        display: flex;
      }

      .tabs {
        margin-top: 30px;

        .tab-nav {
          display: flex;
          justify-content: center;

          .nav {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 24px;

            .title {
              display: flex;

              .text {
                @include category-medium();
                color: $text-additional;
              }

              .badge {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 12px;
                height: 12px;
                background: $accent;
                border-radius: 50%;
                margin-top: -4px;
                margin-left: -2px;

                span {
                  color: $white;
                  font-size: 10px;
                  line-height: 10px;
                }
              }
            }

            .underline {
              margin-top: 8px;
              height: 2px;
            }

            &.active {
              .title {
                .text {
                  color: $text;
                }
              }

              .underline {
                margin-top: 8px;
                width: calc(100% - 20px);
                height: 2px;
                background: $accent;
                border-radius: 50px;
              }
            }
          }
        }

        .tab-content {
          .payments {
            margin-top: 32px;

            .payment {
              display: flex;
              align-items: center;
              margin-bottom: 28px;

              .user-image {
                width: 44px;
                height: 44px;
                overflow: hidden;
                border-radius: 50%;

                img {
                  object-fit: cover;
                }
              }

              .user-initials {
                width: 44px;
                height: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: sans-serif;
                font-size: 17px;
                background-color: $black;
                border: 1px solid $accent;
                color: $white;
                border-radius: 50%;
              }

              .user-details {
                flex: 1;
                display: flex;
                flex-direction: column;
                margin-left: 16px;

                .name {
                  @include category-medium(16px, 16px);
                }

                .date {
                  @include body-light(16px, 20px);
                  color: $text-additional;
                  margin-top: 8px;
                }
              }

              .payment-details {
                display: flex;
                flex-direction: column;

                .status {
                  @include date-time-light();
                  color: $text-additional;
                  margin-bottom: 4px;
                }

                .amount {
                  font-weight: bold;
                  font-size: 18px;
                  line-height: 18px;
                  letter-spacing: -0.22px;

                  &.accent {
                    color: $accent;
                  }
                }
              }
            }
          }
        }
      }
    }

    .right-panel {
      padding: 60px;
      background: $bg;
      height: 100vh;

      .user {
        display: flex;
        flex-direction: row;
        align-items: center;

        .image {
          width: 100px;
          height: 100px;
          overflow: hidden;
          border-radius: 50%;

          img {
            object-fit: cover;
          }
        }

        .initials {
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: sans-serif;
          font-size: 36px;
          background-color: $accent;
          color: $white;
          border-radius: 50%;
        }

        .balance {
          margin-left: 40px;

          .balance-text {
            @include body-light();
            color: $text-additional;
          }

          .balance-amount {
            margin-top: 5px;
            font-weight: bold;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: -0.22px;
            color: black;
          }
        }

        button {
          margin-left: auto;
        }
      }

      .payment-methods {
        margin-top: 5rem;

        .title {
          font-weight: bold;
        }

        .description {
          margin-top: 1rem;
          font-weight: 300;
        }

        .table-container {
          margin-top: 1rem;
          background-color: $white;

          thead {
            border-bottom-color: $white;
            font-weight: 300;
          }
        }

        .check {
          margin: 2px 15px 0;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $accent;
          overflow: hidden;
          border-radius: 50%;

          img {
            object-fit: cover;
            width: 12px;
            height: 12px;
          }
        }

        .actions {
          text-align: right;
          padding: 0;
          position: relative;

          .icon {
            display: flex;
            flex-direction: column;
            background-color: #e0e0e0;
            align-items: center;
            justify-content: space-between;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            padding: 4px 0;
            margin-left: auto;

            &:hover {
              cursor: pointer;
              background-color: #c9c9c9;
            }

            .dot {
              height: 4px;
              width: 4px;
              border-radius: 50%;
              background-color: $white;
            }
          }

          .menu {
            position: absolute;
            background-color: $bg;
            top: calc(50% - 12px);
            right: 0;
            display: flex;
            flex-direction: column;
            border: 1px solid #ebebeb;
            border-radius: 10px;
            z-index: 11;

            &:focus {
              outline: none;
            }

            .item {
              text-align: left;
              font-size: 0.8rem;
              white-space: nowrap;
              padding: 8px 12px;

              &:hover {
                background-color: #f3f1f1;
                cursor: pointer;
              }
            }
          }
        }

        .no-data {
          td {
            width: 100%;
            text-align: center;

            div {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .add-card {
          margin-top: 1rem;
          display: flex;

          button {
            margin-left: auto;
          }
        }
      }
    }
  }
}
</style>
